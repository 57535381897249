import { Injectable } from '@angular/core';
import { MachineClass, OperationCardAttribute, OperationCardEntity } from '@data-terminal/shared-models';
import { OperationCardAttributeOrderApiService } from './operation-card-attribute-order-api.service';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HdmuiAppearanceCustomizerElement } from '@heidelberg/hdmui-angular';

const EMPTY_STRING = '';
const OPERATIONCARDATTRIBUTE_PREFIX = 'OperationCardAttribute.';

@Injectable({
    providedIn: 'root',
})
export class OperationCardAttributeOrderService {
    readonly #defaultOrder: OperationCardAttribute[] = [
        OperationCardAttribute.jobName,
        OperationCardAttribute.opName,
        OperationCardAttribute.opState,
        OperationCardAttribute.opPlannedStart,
        OperationCardAttribute.opPlannedGoodCtr,
        OperationCardAttribute.versions,
        OperationCardAttribute.plannedMachine,
    ];

    readonly #allCustomizerElements: HdmuiAppearanceCustomizerElement[] = [
        { id: OperationCardAttribute.jobName, visible: true, name: 'DC.OPERATION_CARD.JOBNAME' },
        { id: OperationCardAttribute.opName, visible: true, name: 'DC.OPERATION_CARD.OPERATION_NAME' },
        { id: OperationCardAttribute.opState, visible: true, name: 'DC.OPERATION_CARD.STATE' },
        { id: OperationCardAttribute.opPlannedStart, visible: true, name: 'DC.OPERATION_CARD.STARTTIME' },
        { id: OperationCardAttribute.opPlannedGoodCtr, visible: true, name: 'DC.OPERATION_CARD.PLANNEDAMOUNT' },
        { id: OperationCardAttribute.versions, visible: true, name: 'DC.OPERATION_CARD.VERSIONS' },
        { id: OperationCardAttribute.plannedMachine, visible: true, name: 'DC.OPERATION_CARD.PLANNED_MACHINE' },
        { id: OperationCardAttribute.dueDate, visible: true, name: 'DC.OPERATION_CARD.DUE_DATE' },
        { id: OperationCardAttribute.article, visible: true, name: 'DC.OPERATION_CARD.ARTICLE' },
        { id: OperationCardAttribute.sheet, visible: true, name: 'DC.OPERATION_CARD.SHEET' },
        {
            id: OperationCardAttribute.specialOperationInfo,
            visible: true,
            name: 'DC.OPERATION_CARD.SPECIAL_OPERATION_INFO',
        },
    ];

    readonly #defaultCustomizerElements: HdmuiAppearanceCustomizerElement[] = this.#allCustomizerElements.map(
        (element) => {
            if (this.#defaultOrder.includes(element.id as OperationCardAttribute)) {
                return { ...element, visible: true, name: this.translate.instant(element.name) };
            } else {
                return { ...element, visible: false, name: this.translate.instant(element.name) };
            }
        }
    );

    public readonly operationCardsEntity$ = new BehaviorSubject<OperationCardEntity[]>([]);
    public readonly machineClassesAttributesMap$: Observable<
        Map<MachineClass, HdmuiAppearanceCustomizerElement[] | undefined>
    > = this.operationCardsEntity$.pipe(
        map((entities) => {
            const machineClassesAttributesMap = new Map<MachineClass, HdmuiAppearanceCustomizerElement[] | undefined>();

            entities.forEach((entity) => {
                const cardAttributes = entity.cardAttributes.map((e) => ({
                    id: e.id.replace(OPERATIONCARDATTRIBUTE_PREFIX, EMPTY_STRING),
                    visible: e.visible,
                    name: this.translate.instant(
                        this.#allCustomizerElements.find((dic) => OPERATIONCARDATTRIBUTE_PREFIX + dic.id === e.id)
                            ?.name || 'ENTRY_NOT_FOUND'
                    ),
                }));

                machineClassesAttributesMap.set(entity.machineClass as MachineClass, cardAttributes);
            });

            return machineClassesAttributesMap;
        })
    );

    public operationCardAttributeList: OperationCardEntity[] | undefined = undefined;

    constructor(
        private readonly operationCardAttributeOrderApiService: OperationCardAttributeOrderApiService,
        private readonly translate: TranslateService
    ) {}

    public get defaultCustomizerElements(): HdmuiAppearanceCustomizerElement[] {
        return this.#defaultCustomizerElements;
    }

    public fetchOperationCardAttributes(): Observable<OperationCardEntity[]> {
        return this.operationCardAttributeOrderApiService.getOperationCardAttributeOrder().pipe(
            tap((val) => {
                if (val !== undefined && val !== null) {
                    this.operationCardAttributeList = val;
                    this.operationCardsEntity$.next(this.operationCardAttributeList);
                }
            })
        );
    }

    public getOperationCardOrder$(machineClass: MachineClass): Observable<HdmuiAppearanceCustomizerElement[]> {
        return this.machineClassesAttributesMap$.pipe(
            map((machineClassesAttributesMap) => {
                const mapResult = machineClassesAttributesMap.get(machineClass);
                if (mapResult) {
                    return mapResult;
                } else {
                    return this.defaultCustomizerElements;
                }
            })
        );
    }

    public getOperationCardOrderNames$(machineClass: MachineClass): Observable<string[]> {
        return this.machineClassesAttributesMap$.pipe(
            map((machineClassesAttributesMap) => {
                const mapResult = machineClassesAttributesMap.get(machineClass);
                if (mapResult) {
                    return mapResult
                        .filter((e) => e.visible)
                        .map((e) => e.id)
                        .map((e) => e.replace(OPERATIONCARDATTRIBUTE_PREFIX, EMPTY_STRING));
                } else {
                    return this.defaultCustomizerElements
                        .filter((e) => e.visible)
                        .map((e) => e.id)
                        .map((e) => e.replace(OPERATIONCARDATTRIBUTE_PREFIX, EMPTY_STRING));
                }
            })
        );
    }

    public saveNewOrder(newOrder: HdmuiAppearanceCustomizerElement[], machineClass: MachineClass): void {
        if (this.operationCardAttributeList !== undefined && this.operationCardAttributeList !== null) {
            const targetObject = this.operationCardAttributeList.find(
                (val) => val.machineClass.toUpperCase() === machineClass.toUpperCase()
            );
            if (targetObject !== undefined && targetObject !== null) {
                targetObject.cardAttributes = newOrder.map((nO) => {
                    return {
                        id: nO.id,
                        visible: nO.visible,
                    };
                });
                this.operationCardAttributeOrderApiService
                    .postOperationCardAttributeOrder(targetObject)
                    .subscribe(() => this.fetchOperationCardAttributes().subscribe());
            } else {
                throw Error('Found no Object to update!');
            }
        }
    }
}
