@if (!isCloudAdmin) {
    <hdmui-info-box [icon]="'hdmui:exclamationMarkCircled'" [background]="'full'">
        <p>
            {{ 'DC.SETTINGS.USER_NOT_CLOUD_ADMIN' | translate }}
        </p>
    </hdmui-info-box>
}
@if (dataTransferSettings !== undefined) {
    <section class="hdmui">
        <h1>{{ 'DC.SETTINGS.DATATRANSFER.TITLE' | translate }}</h1>
        <div class="hdmui-content hdmui-m-4 hdmui-background-2">
            <p>{{ 'DC.SETTINGS.DATATRANSFER.DESCRIPTION' | translate }}</p>
            <p>
                <span class="hdmui-mt-3"> {{ 'DC.SETTINGS.DATATRANSFER.TRANSFER_ALL' | translate }} </span>
                <span class="hdmui-spacer"></span>
                <mat-slide-toggle
                    [checked]="transferAllData"
                    (change)="changeTransferAllData()"
                    [disabled]="!isCloudAdmin"
                ></mat-slide-toggle>
            </p>
            <mat-divider></mat-divider>
            <p>
                <span class="hdmui-mt-3"> {{ 'DC.SETTINGS.DATATRANSFER.ORDERBAG' | translate }} </span>
                <span class="hdmui-spacer"></span>
                <mat-slide-toggle
                    [checked]="orderbagValue"
                    (change)="changeOrderbagValue()"
                    [disabled]="!isCloudAdmin"
                ></mat-slide-toggle>
            </p>
            <p>
                <span class="hdmui-mt-3"> {{ 'DC.SETTINGS.DATATRANSFER.PREVIEW' | translate }} </span>
                <span class="hdmui-spacer"></span>
                <mat-slide-toggle
                    [checked]="previewValue"
                    (change)="changePreviewValue()"
                    [disabled]="!isCloudAdmin"
                ></mat-slide-toggle>
            </p>
            <p>
                <span class="hdmui-mt-3"> {{ 'DC.SETTINGS.DATATRANSFER.OPERATIONCARD' | translate }} </span>
                <span class="hdmui-spacer"></span>
                <mat-slide-toggle
                    [checked]="operationcardValue"
                    (change)="changeOperationcardValue()"
                    [disabled]="!isCloudAdmin"
                ></mat-slide-toggle>
            </p>
            <div>
                <button
                    mat-flat-button
                    (click)="updateDataTransferSettings()"
                    [disabled]="!isCloudAdmin || !settingsChanged"
                    class="settings-data-transfer__confirmation-button"
                >
                    {{ 'DC.SETTINGS.DATATRANSFER.SAVE_CHANGES' | translate }}
                </button>
            </div>
        </div>
    </section>
} @else {
    <data-terminal-loading-indicator></data-terminal-loading-indicator>
}
