import { Component, NgModule, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { interval, Subject, take, takeUntil, tap } from 'rxjs';

import { HdmuiComponentsModule, HdmuiInfoBoxModule } from '@heidelberg/hdmui-angular';
import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { SettingsOperationsService } from '@data-terminal/feature-settings';
import { DataTerminalSessionService } from '@data-terminal/data-access';
import { MachineClass } from '@data-terminal/shared-models';

import { LoadingIndicatorComponentModule } from '@data-terminal/ui-presentational';

import { SettingsWorkstationOperationCardModule } from '../../components/settings-operation-card/settings-operation-card.component';

@Component({
    selector: 'data-terminal-settings-operations',
    templateUrl: './settings-operations.component.html',
    styleUrls: ['./settings-operations.component.scss'],
})
export class SettingsOperationsComponent implements OnInit {
    private readonly LIST_OF_MACHINE_CLASSES_KEY = 'LIST_OF_MACHINE_CLASSES';
    public readonly defaultClass = MachineClass.DEFAULT;
    public listOfMachineClasses: MachineClass[] | undefined = undefined;

    public selectedMachineClass: MachineClass = MachineClass.DEFAULT;
    public isDataTerminalUser = true;

    constructor(
        private readonly settingsOperationsService: SettingsOperationsService,
        private readonly dataTerminalSessionService: DataTerminalSessionService,
        private readonly ccAuthService: CCAuthService
    ) {
        let dataTerminalUser = this.dataTerminalSessionService.getCurrentDataTerminalUser();
        const userId = this.ccAuthService.getCurrentUser()?.id;
        const userMail = this.ccAuthService.getCurrentUser()?.email;
        const orgName = this.ccAuthService.getCurrentMembership()?.organization.name;

        if ((dataTerminalUser === null || dataTerminalUser === undefined) && userId && userMail && orgName) {
            const REFRESH_INTERVAL_1250 = 1250;
            this.dataTerminalSessionService.logInToDataTerminal(userId, userMail, orgName);
            interval(REFRESH_INTERVAL_1250)
                .pipe(
                    take(1),
                    tap(() => {
                        dataTerminalUser = this.dataTerminalSessionService.getCurrentDataTerminalUser();
                        this.isDataTerminalUser = !!(dataTerminalUser !== null && dataTerminalUser !== undefined);
                    })
                )
                .subscribe();
        }
        this.isDataTerminalUser = !!(dataTerminalUser !== null && dataTerminalUser !== undefined);
    }

    ngOnInit(): void {
        const REFRESH_INTERVAL_1000 = 1000;
        const loadingDone$ = new Subject<void>();
        interval(REFRESH_INTERVAL_1000)
            .pipe(takeUntil(loadingDone$))
            .subscribe(() => {
                this.listOfMachineClasses = this.settingsOperationsService.getMachineClasses();
                if (this.listOfMachineClasses !== null && this.listOfMachineClasses !== undefined) {
                    this.selectedMachineClass = this.listOfMachineClasses[0];
                    loadingDone$.next();
                }
            });
    }
}

@NgModule({
    declarations: [SettingsOperationsComponent],
    exports: [SettingsOperationsComponent],
    imports: [
        SettingsWorkstationOperationCardModule,
        TranslateModule,
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        HdmuiInfoBoxModule,
        LoadingIndicatorComponentModule,
        HdmuiComponentsModule,
    ],
})
export class SettingsOperationsModule {}
