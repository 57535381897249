import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, NgModule, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { interval, Subject, takeUntil } from 'rxjs';

import { HdmuiInfoBoxModule } from '@heidelberg/hdmui-angular';
import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { SettingsDataTransferService } from '@data-terminal/feature-settings';
import { OperationCardAttributeOrderService } from '@data-terminal/settings';
import { LoadingIndicatorComponentModule } from '@data-terminal/ui-presentational';
import { CCRoleId } from '@data-terminal/shared-models';

import { DataTransferSettings } from 'projects/shared-models/src/lib/datatransfer';

@Component({
    selector: 'data-terminal-settings-data-transfer',
    templateUrl: './settings-data-transfer.component.html',
    styleUrls: ['./settings-data-transfer.component.scss'],
})
export class SettingsDataTransferComponent implements OnInit {
    public transferAllData = false;
    public orderbagValue = false;
    public previewValue = false;
    public operationcardValue = false;

    public dataTransferSettings: undefined | DataTransferSettings;
    public previousDataTransferSettings: undefined | DataTransferSettings;
    public settingsChanged = false;

    public isCloudAdmin = false;
    private readonly cloudAdminEmail?: string;

    constructor(
        private readonly settingsDataTransferService: SettingsDataTransferService,
        private readonly operationCardAttributeOrderService: OperationCardAttributeOrderService,
        private readonly ccAuthService: CCAuthService,
        private readonly cdRef: ChangeDetectorRef
    ) {
        this.isCloudAdmin = !!this.ccAuthService.getCurrentMembership()?.ccRoles?.find((role) => role === CCRoleId.adm);
        this.cloudAdminEmail = this.ccAuthService.getCurrentUser()?.email;
    }

    ngOnInit(): void {
        const REFRESH_INTERVAL_250 = 250;
        let dataTransferSettings = this.settingsDataTransferService.getDataTransferSettings();
        const loadingDone$ = new Subject<void>();
        if (dataTransferSettings === null || dataTransferSettings === undefined) {
            interval(REFRESH_INTERVAL_250)
                .pipe(takeUntil(loadingDone$))
                .subscribe(() => {
                    dataTransferSettings = this.settingsDataTransferService.getDataTransferSettings();
                    if (dataTransferSettings !== undefined && dataTransferSettings !== null) {
                        loadingDone$.next();
                        dataTransferSettings.cloudAdminName = this.cloudAdminEmail;
                        this.dataTransferSettings = dataTransferSettings;
                        this.previousDataTransferSettings = JSON.parse(JSON.stringify(dataTransferSettings));
                        this.orderbagValue = this.dataTransferSettings.orderbag;
                        this.previewValue = this.dataTransferSettings.previews;
                        this.operationcardValue = this.dataTransferSettings.customerName;
                        this.transferAllData = this.orderbagValue && this.previewValue && this.operationcardValue;
                        this.cdRef.detectChanges();
                    }
                });
        } else {
            dataTransferSettings.cloudAdminName = this.cloudAdminEmail;
            this.dataTransferSettings = dataTransferSettings;
            this.previousDataTransferSettings = JSON.parse(JSON.stringify(dataTransferSettings));
            this.orderbagValue = this.dataTransferSettings.orderbag;
            this.previewValue = this.dataTransferSettings.previews;
            this.operationcardValue = this.dataTransferSettings.customerName;
            this.transferAllData = this.orderbagValue && this.previewValue && this.operationcardValue;
            this.cdRef.detectChanges();
        }
    }

    public changeOrderbagValue(): void {
        if (this.dataTransferSettings !== undefined) {
            this.orderbagValue = !this.orderbagValue;
            this.dataTransferSettings.orderbag = this.orderbagValue;
            this.transferAllData = this.orderbagValue && this.previewValue && this.operationcardValue;
            this.settingsChanged =
                JSON.stringify(this.dataTransferSettings) !== JSON.stringify(this.previousDataTransferSettings);
            this.cdRef.detectChanges();
        }
    }

    public changePreviewValue(): void {
        if (this.dataTransferSettings !== undefined) {
            this.previewValue = !this.previewValue;
            this.dataTransferSettings.previews = this.previewValue;
            this.transferAllData = this.orderbagValue && this.previewValue && this.operationcardValue;
            this.settingsChanged =
                JSON.stringify(this.dataTransferSettings) !== JSON.stringify(this.previousDataTransferSettings);
            this.cdRef.detectChanges();
        }
    }

    public changeOperationcardValue(): void {
        if (this.dataTransferSettings !== undefined) {
            this.operationcardValue = !this.operationcardValue;
            this.dataTransferSettings.customerName = this.operationcardValue;
            this.transferAllData = this.orderbagValue && this.previewValue && this.operationcardValue;
            this.operationCardAttributeOrderService.fetchOperationCardAttributes();
            this.settingsChanged =
                JSON.stringify(this.dataTransferSettings) !== JSON.stringify(this.previousDataTransferSettings);
            this.cdRef.detectChanges();
        }
    }

    public changeTransferAllData(): void {
        if (this.dataTransferSettings !== undefined) {
            this.transferAllData = !this.transferAllData;
            this.orderbagValue = this.transferAllData;
            this.previewValue = this.transferAllData;
            this.operationcardValue = this.transferAllData;
            this.dataTransferSettings.orderbag = this.orderbagValue;
            this.dataTransferSettings.previews = this.previewValue;
            this.dataTransferSettings.customerName = this.operationcardValue;
            this.operationCardAttributeOrderService.fetchOperationCardAttributes();
            this.settingsChanged =
                JSON.stringify(this.dataTransferSettings) !== JSON.stringify(this.previousDataTransferSettings);
            this.cdRef.detectChanges();
        }
    }

    public updateDataTransferSettings(): void {
        if (this.dataTransferSettings !== undefined) {
            this.settingsDataTransferService.setDataTransferSettings(this.dataTransferSettings);
            this.previousDataTransferSettings = JSON.parse(JSON.stringify(this.dataTransferSettings));
            this.settingsChanged = false;
            this.cdRef.detectChanges();
        }
    }
}

@NgModule({
    declarations: [SettingsDataTransferComponent],
    exports: [SettingsDataTransferComponent],
    imports: [
        MatSlideToggleModule,
        MatDividerModule,
        TranslateModule,
        HdmuiInfoBoxModule,
        CommonModule,
        MatButtonModule,
        LoadingIndicatorComponentModule,
    ],
})
export class SettingsDataTransferModule {}
