import { Component } from '@angular/core';
import { ROUTE_PATH } from '@data-terminal/shared-models';

@Component({
    selector: 'data-terminal-settings-wrapper',
    templateUrl: './settings-wrapper.component.html',
    styleUrls: ['./settings-wrapper.component.scss'],
})
export class SettingsWrapperComponent {
    public readonly links = [
        { path: `./${ROUTE_PATH.settingsConfirmation}`, title: 'DC.SETTINGS.NAV.NOTIFICATIONS' },
        { path: `./${ROUTE_PATH.settingsDataTransfer}`, title: 'DC.SETTINGS.NAV.DATATRANSFER' },
        { path: `./${ROUTE_PATH.operations}`, title: 'DC.SETTINGS.NAV.OPERATIONS' },
    ];
}
