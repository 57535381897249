@if (!isDataTerminalUser) {
    <hdmui-info-box [icon]="'hdmui:exclamationMarkCircled'" [background]="'full'">
        <p>
            {{ 'DC.SETTINGS.USER_NOT_FOUND_ONPREM' | translate }}
        </p>
    </hdmui-info-box>
}
<section class="hdmui" ng-disabled="true">
    <h1>{{ 'DC.SETTINGS.OPERATIONS.TITLE' | translate }}</h1>
    <div class="hdmui-content hdmui-m-4 hdmui-background-2">
        <p>
            {{ 'DC.SETTINGS.OPERATIONS.DESCRIPTION' | translate }}
        </p>
        <data-terminal-settings-operation-card
            [machineClass]="defaultClass"
            class="hdmui-m-4"
            [disabled]="!isDataTerminalUser"
        ></data-terminal-settings-operation-card>

        @if (listOfMachineClasses !== undefined) {
            <div>
                <mat-form-field style="width: 30%" class="hdmui-m-0 hdmui-p-0 hdmui-mx-4">
                    <mat-label>Machine class</mat-label>
                    <mat-select [(ngModel)]="selectedMachineClass" [hideSingleSelectionIndicator]="true">
                        @for (machineClass of listOfMachineClasses; track machineClass) {
                            <mat-option [value]="machineClass" [disabled]="!isDataTerminalUser">
                                {{ 'DC.MACHINE_CLASS.' + machineClass.toUpperCase() | translate }}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <data-terminal-settings-operation-card
                    [machineClass]="selectedMachineClass"
                    class="hdmui-m-4"
                    [disabled]="!isDataTerminalUser"
                ></data-terminal-settings-operation-card>
            </div>
        } @else {
            <data-terminal-loading-indicator></data-terminal-loading-indicator>
        }
    </div>
</section>
