import { CommonModule } from '@angular/common';
import { Component, inject, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { OperationCardAttributeOrderService } from '@data-terminal/settings';
import { MachineClass, Operation, OperationNeedsAvailable } from '@data-terminal/shared-models';
import { OperationCardModule } from '@data-terminal/ui-smart';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    HdmuiAppearanceCustomizerComponent,
    HdmuiAppearanceCustomizerCounter,
    HdmuiAppearanceCustomizerElement,
    HdmuiAppearanceCustomizerEntry,
    HdmuiAppearanceCustomizerEntryType,
    HdmuiAppearanceCustomizerReset,
    HdmuiAppearanceCustomizerValidation,
    HdmuiComponentsModule,
} from '@heidelberg/hdmui-angular';
import { LoadingIndicatorComponentModule } from '@data-terminal/ui-presentational';
import { Observable } from 'rxjs';

@Component({
    selector: 'data-terminal-settings-operation-card',
    templateUrl: './settings-operation-card.component.html',
    styleUrls: ['./settings-operation-card.component.scss'],
})
export class SettingsOperationCardComponent implements OnChanges {
    readonly #translateService = inject(TranslateService);
    readonly #orderService = inject(OperationCardAttributeOrderService);

    @Input() public machineClass: MachineClass = '' as MachineClass;
    @Input() public disabled = false;

    public elements$!: Observable<HdmuiAppearanceCustomizerElement[]>;
    public elementNames$!: Observable<string[]>;

    public operation = {
        opName: 'FB 002',
        opState: 'Waiting',
        opNeedsAvailable: OperationNeedsAvailable.GREEN,
        opPlannedStart: 1751295520000,
        jobNumber: 'plan-200',
        jobName: 'plan-200-test',
        opCtrGood: 60,
        opPlannedGoodCtr: 100,
        versions: '1.10',
        customerName: 'Customer',
        plannedMachine: '5000',
        dueDate: new Date().getTime(),
        sheetId: '123',
        article: 'Article',
        specialOperationInfo: 'F16-6 Animation',
        pred_good: '',
    } as Operation;

    constructor() {
        this.receiveOrderServiceData();
    }

    private receiveOrderServiceData(): void {
        this.elements$ = this.#orderService.getOperationCardOrder$(this.machineClass);
        this.elementNames$ = this.#orderService.getOperationCardOrderNames$(this.machineClass);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['machineClass']) {
            this.receiveOrderServiceData();
        }
    }

    get entry(): HdmuiAppearanceCustomizerEntry {
        return this.ENTRY_DATA;
    }

    get counter(): HdmuiAppearanceCustomizerCounter {
        return this.COUNTER_DATA;
    }

    get reset(): HdmuiAppearanceCustomizerReset {
        return this.RESET_DATA;
    }

    get validation(): HdmuiAppearanceCustomizerValidation {
        return this.VALIDATION_DATA;
    }

    public onElementsChangeEvent(elements: HdmuiAppearanceCustomizerElement[], machineClass: MachineClass): void {
        this.#orderService.saveNewOrder(
            elements.map((attr) => {
                return {
                    id: 'OperationCardAttribute.' + attr.id,
                    name: attr.name,
                    visible: attr.visible,
                };
            }),
            machineClass
        );
    }

    private readonly ENTRY_DATA: HdmuiAppearanceCustomizerEntry = {
        entryText: this.#translateService.instant('*.appearanceCustomizer.entryText'),
        entryType: HdmuiAppearanceCustomizerEntryType.ICON_BUTTON,
    };

    private readonly COUNTER_DATA: HdmuiAppearanceCustomizerCounter = {
        counterText: this.#translateService.instant('*.appearanceCustomizer.selected'),
        lowerBoundHint: this.#translateService.instant('*.appearanceCustomizer.lowerBoundHint'),
        upperBoundHint: this.#translateService.instant('*.appearanceCustomizer.upperBoundHint'),
        lowerBound: 1,
        upperBound: 8,
    };

    private readonly RESET_DATA: HdmuiAppearanceCustomizerReset = {
        resetText: this.#translateService.instant('*.reset'),
        resetToDefaultText: this.#translateService.instant('*.reset_to_default'),
        resetToLastSavedText: this.#translateService.instant('*.reset_to_last_saved'),
        applicationDefault: this.#orderService.defaultCustomizerElements,
    };

    private readonly VALIDATION_DATA: HdmuiAppearanceCustomizerValidation = {
        cancelText: this.#translateService.instant('*.action.cancel'),
        discardText: this.#translateService.instant('*.discard_changes'),
        acceptText: this.#translateService.instant('*.action.save'),

        validationOnCloseText: this.#translateService.instant('*.action.discardChanges.question'),
        validationOnCloseHint: this.#translateService.instant('*.appearanceCustomizer.validationOnCloseHint'),

        validationOnResetText: this.#translateService.instant('*.appearanceCustomizer.validationOnResetText'),
        validationOnResetHint: this.#translateService.instant('*.appearanceCustomizer.validationOnResetHint'),
    };
}

@NgModule({
    declarations: [SettingsOperationCardComponent],
    exports: [SettingsOperationCardComponent],
    imports: [
        OperationCardModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule,
        CommonModule,
        HdmuiAppearanceCustomizerComponent,
        LoadingIndicatorComponentModule,
        HdmuiComponentsModule,
    ],
})
export class SettingsWorkstationOperationCardModule {}
