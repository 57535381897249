import { Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SETTINGS_ROUTES } from './feature-settings.routes';
import { FeatureSettingsModule } from './feature-settings.module';

@Component({
    selector: 'data-terminal-feature-settings',
    templateUrl: './feature-settings.component.html',
})
export class FeatureSettingsComponent {}

@NgModule({
    declarations: [FeatureSettingsComponent],
    exports: [FeatureSettingsComponent],
    imports: [FeatureSettingsModule, RouterModule.forChild(SETTINGS_ROUTES)],
})
export class FeatureSettingsComponentModule {}
