import { Injectable } from '@angular/core';
import { SettingsOperationsApiService } from '../settings-operations-api/settings-operations-api.service';
import { MachineClass } from '@data-terminal/shared-models';
import { Observable, tap } from 'rxjs';
import { RequestMetadata } from '@data-terminal/utils';

@Injectable({
    providedIn: 'root',
})
export class SettingsOperationsService {
    private static readonly listOfMachineClasses: MachineClass[] = [
        MachineClass.ID_SHEETFOLDER,
        MachineClass.ID_ADHESIVEBINDER,
        MachineClass.ID_ADHESIVEBINDERLINE,
        MachineClass.ID_BOXMAKER,
        MachineClass.ID_BOOKPRODUCTIONLINE,
        MachineClass.ID_BOXPACKER,
        MachineClass.ID_CUTTER,
        MachineClass.ID_CASEMAKER,
        MachineClass.ID_DIGITALPRINTER,
        MachineClass.ID_MANUALPREPRESS,
        MachineClass.ID_MANUALPRESS,
        MachineClass.ID_POSTPRESSEXTERNAL,
        MachineClass.ID_MANUALPOSTPRESS,
        MachineClass.ID_GATHERER,
        MachineClass.ID_SHEETFEDPRESS,
        MachineClass.ID_SHAPECUTTER,
        MachineClass.ID_STITCHER,
        MachineClass.ID_THREADSEWER,
        MachineClass.ID_TRIMMER,
    ];
    private machineClasses: MachineClass[] | undefined = undefined;
    constructor(private readonly settingsOperationApi: SettingsOperationsApiService) {}

    public fetchMachineClasses(): Observable<RequestMetadata<MachineClass[]>> {
        return this.settingsOperationApi.getMachineClasses().pipe(
            tap((val) => {
                if (val.data !== undefined && val.data !== null) {
                    this.machineClasses = val.data.map((v) => (v.includes('ID_') ? v : 'ID_' + v)) as MachineClass[];
                }
            })
        );
    }

    public getMachineClasses(): MachineClass[] | undefined {
        return this.machineClasses;
    }

    public static getDefaultListOfMachineClasses(): MachineClass[] {
        return SettingsOperationsService.listOfMachineClasses;
    }
}
