import { Injectable } from '@angular/core';
import { SettingsConfirmationApiService } from '../settings-confirmation-api/settings-confirmation-api.service';
import { Observable, switchMap, tap } from 'rxjs';
import { ConfirmationSettings } from 'projects/shared-models/src/lib/confirmation';
import { DataTerminalSessionService } from '@data-terminal/data-access';

@Injectable({
    providedIn: 'root',
})
export class SettingsConfirmationService {
    private static readonly defaultConfirmationData: ConfirmationSettings = {
        productionRun: true,
        makeready: true,
        otherActivity: true,
        printPalletSheet: true,
        idle: true,
        idletime: 20,
    };
    private confirmationData = SettingsConfirmationService.defaultConfirmationData;

    constructor(
        private readonly settingsConfirmationApi: SettingsConfirmationApiService,
        private readonly dataTerminalSessionService: DataTerminalSessionService
    ) {}

    public fetchConfirmationSettings(userId?: string): Observable<ConfirmationSettings> {
        if (userId === null || userId === undefined) {
            userId = this.dataTerminalSessionService.getCurrentDataTerminalUser().userId;
        }
        return this.settingsConfirmationApi.getConfirmationSettings(userId).pipe(
            tap((val) => {
                if (val !== null && val !== undefined) {
                    this.confirmationData = val;
                } else {
                    this.confirmationData = SettingsConfirmationService.defaultConfirmationData;
                }
            })
        );
    }

    public getConfirmationSettingsData(): ConfirmationSettings {
        return this.confirmationData;
    }

    public pushConfirmationSettings(body: ConfirmationSettings): void {
        this.settingsConfirmationApi
            .pushConfirmationSettings(body)
            .pipe(switchMap(() => this.fetchConfirmationSettings()))
            .subscribe();
    }

    public static getDefaultConfirmationData(): ConfirmationSettings {
        return SettingsConfirmationService.defaultConfirmationData;
    }

    public updateConfirmationEntry(entry: string, state: boolean): void {
        switch (entry) {
            case 'Makeready':
                this.confirmationData.makeready = !state;
                break;
            case 'Produktionslauf':
                this.confirmationData.productionRun = !state;
                break;
            case 'PrintPalletSheet':
                this.confirmationData.printPalletSheet = !state;
                break;
            default:
                this.confirmationData.otherActivity = !state;
                break;
        }
        this.pushConfirmationSettings(this.confirmationData);
    }

    public showConfirmationDialog(entry: string): boolean {
        switch (entry) {
            case 'Makeready':
                if (this.confirmationData.makeready !== undefined && this.confirmationData.makeready !== null) {
                    return this.confirmationData.makeready;
                }
                return true;
            case 'Produktionslauf':
                if (this.confirmationData.productionRun !== undefined && this.confirmationData.productionRun !== null) {
                    return this.confirmationData.productionRun;
                }
                return true;
            case 'PrintPalletSheet':
                if (
                    this.confirmationData.printPalletSheet !== undefined &&
                    this.confirmationData.printPalletSheet !== null
                ) {
                    return this.confirmationData.printPalletSheet;
                }
                return true;
            default:
                if (this.confirmationData.otherActivity !== undefined && this.confirmationData.otherActivity !== null) {
                    return this.confirmationData.otherActivity;
                }
                return true;
        }
    }
}
