import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BACKEND_URL, DataTerminalSessionService, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { ConfirmationSettings } from 'projects/shared-models/src/lib/confirmation';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SettingsConfirmationApiService {
    private readonly CONFIRMATIONSETTINGS_URL: (userId: string) => string = (userId) =>
        `${this.backendUrl}${this.orgRequestPrefix}confirmation/${userId}`;

    constructor(
        private readonly http: HttpClient,
        private readonly dataTerminalSessionService: DataTerminalSessionService,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    public getConfirmationSettings(userId: string): Observable<ConfirmationSettings> {
        return this.http.get<ConfirmationSettings>(this.CONFIRMATIONSETTINGS_URL(userId));
    }

    public pushConfirmationSettings(body: ConfirmationSettings): Observable<ConfirmationSettings[]> {
        return this.http.post<ConfirmationSettings[]>(
            this.CONFIRMATIONSETTINGS_URL(this.dataTerminalSessionService.getCurrentDataTerminalUser().userId),
            body
        );
    }
}
