import { Routes } from '@angular/router';

import { ROUTE_PATH, RouteData } from '@data-terminal/shared-models';

import { SettingsWrapperComponent } from './pages/settings-wrapper/settings-wrapper.component';
import { SettingsConfirmationComponent } from './pages/settings-confirmation/settings-confirmation.component';
import { SettingsOperationsComponent } from './pages/settings-operations/settings-operations.component';
import { SettingsDataTransferComponent } from './pages/settings-data-transfer/settings-data-transfer/settings-data-transfer.component';

export const SETTINGS_ROUTES: Routes = [
    {
        path: '',
        component: SettingsWrapperComponent,
        data: { isLegalLineHidden: true } as RouteData,
        children: [
            {
                path: ``,
                redirectTo: `${ROUTE_PATH.settingsConfirmation}`,
                pathMatch: 'full',
            },
            {
                path: `${ROUTE_PATH.settingsConfirmation}`,
                component: SettingsConfirmationComponent,
                data: { goToParentSegments: -2 },
            },
            {
                path: `${ROUTE_PATH.settingsDataTransfer}`,
                component: SettingsDataTransferComponent,
                data: { goToParentSegments: -2 },
            },
            {
                path: `${ROUTE_PATH.operations}`,
                component: SettingsOperationsComponent,
                data: { goToParentSegments: -2 },
            },
        ],
    },
];
