import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { MachineClass } from '@data-terminal/shared-models';
import { RETRY_TIME_DELAY_MS, RequestMetadata, mapToRequestMetadataWithRetry } from '@data-terminal/utils';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SettingsOperationsApiService {
    private readonly MACHINECLASS_URL = `${this.backendUrl}${this.orgRequestPrefix}getmachineclasslist`;

    constructor(
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    public getMachineClasses(): Observable<RequestMetadata<MachineClass[]>> {
        return this.http.get<MachineClass[]>(this.MACHINECLASS_URL).pipe(
            mapToRequestMetadataWithRetry({
                baseDelayMs: RETRY_TIME_DELAY_MS,
            })
        );
    }
}
