import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AngularSplitModule } from 'angular-split';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';

import { SettingsWrapperComponent } from './pages/settings-wrapper/settings-wrapper.component';
import { SettingsConfirmationModule } from './pages/settings-confirmation/settings-confirmation.component';
import { SettingsOperationsModule } from './pages/settings-operations/settings-operations.component';
import { LegalLineComponent } from '../../../../src/app/components/legal-line/legal-line.component';

@NgModule({
    declarations: [SettingsWrapperComponent],
    exports: [SettingsWrapperComponent],
    imports: [
        SettingsConfirmationModule,
        SettingsOperationsModule,
        RouterModule,
        MatSidenavModule,
        AngularSplitModule,
        MatTabsModule,
        TranslateModule,
        CommonModule,
        MatListModule,
        LegalLineComponent,
    ],
})
export class FeatureSettingsModule {}
