import { Component, NgModule, OnInit } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { interval, take, tap } from 'rxjs';

import { HdmuiInfoBoxModule } from '@heidelberg/hdmui-angular';
import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { SettingsConfirmationService } from '@data-terminal/feature-settings';
import { DataTerminalSessionService } from '@data-terminal/data-access';
import { ConfirmationSettings } from 'projects/shared-models/src/lib/confirmation';

@Component({
    selector: 'data-terminal-settings-confirmation',
    templateUrl: './settings-confirmation.component.html',
    styleUrls: ['./settings-confirmation.component.scss'],
})
export class SettingsConfirmationComponent implements OnInit {
    public static readonly CONFIRMATION_KEY = 'CONFIRMATION_KEY';

    public defaultConfirmationData = SettingsConfirmationService.getDefaultConfirmationData();
    public showAllDialogs = false;
    public productionRunValue =
        this.defaultConfirmationData.productionRun !== undefined ? this.defaultConfirmationData.productionRun : false;
    public makereadyValue =
        this.defaultConfirmationData.makeready !== undefined ? this.defaultConfirmationData.makeready : false;
    public otherActivityValue =
        this.defaultConfirmationData.otherActivity !== undefined ? this.defaultConfirmationData.otherActivity : false;
    public printPalletSheetValue =
        this.defaultConfirmationData.printPalletSheet !== undefined
            ? this.defaultConfirmationData.printPalletSheet
            : false;
    public idleValue = this.defaultConfirmationData.idle;
    public idleTime = this.defaultConfirmationData.idletime;
    public confirmationData = this.defaultConfirmationData;
    public subscriptionCount = 0;

    public isDataTerminalUser = true;

    constructor(
        private readonly settingsConfirmationService: SettingsConfirmationService,
        private readonly dataTerminalSessionService: DataTerminalSessionService,
        private readonly ccAuthService: CCAuthService
    ) {
        let dataTerminalUser = this.dataTerminalSessionService.getCurrentDataTerminalUser();
        const userId = this.ccAuthService.getCurrentUser()?.id;
        const userMail = this.ccAuthService.getCurrentUser()?.email;
        const orgName = this.ccAuthService.getCurrentMembership()?.organization.name;

        if ((dataTerminalUser === null || dataTerminalUser === undefined) && userId && userMail && orgName) {
            const REFRESH_INTERVAL = 1250;
            this.dataTerminalSessionService.logInToDataTerminal(userId, userMail, orgName);
            interval(REFRESH_INTERVAL)
                .pipe(
                    take(1),
                    tap(() => {
                        dataTerminalUser = this.dataTerminalSessionService.getCurrentDataTerminalUser();
                        this.isDataTerminalUser = !!(dataTerminalUser !== null && dataTerminalUser !== undefined);
                    })
                )
                .subscribe();
        }
        this.isDataTerminalUser = !!(dataTerminalUser !== null && dataTerminalUser !== undefined);
    }

    ngOnInit(): void {
        this.confirmationData = this.settingsConfirmationService.getConfirmationSettingsData();
        if (this.confirmationData.productionRun !== undefined) {
            this.productionRunValue = this.confirmationData.productionRun;
        }
        if (this.confirmationData.makeready !== undefined) {
            this.makereadyValue = this.confirmationData.makeready;
        }
        if (this.confirmationData.otherActivity !== undefined) {
            this.otherActivityValue = this.confirmationData.otherActivity;
        }
        if (this.confirmationData.printPalletSheet !== undefined) {
            this.printPalletSheetValue = this.confirmationData.printPalletSheet;
        }
        if (this.confirmationData.idle !== undefined && this.confirmationData.idletime !== undefined) {
            this.idleValue = this.confirmationData.idle;
            this.idleTime = this.confirmationData.idletime;
        }
        this.showAllDialogs =
            this.productionRunValue && this.makereadyValue && this.otherActivityValue && this.printPalletSheetValue;
    }

    public changeProductionRunValue(): void {
        this.productionRunValue = !this.productionRunValue;
        this.confirmationData.productionRun = this.productionRunValue;
        this.showAllDialogs = this.productionRunValue && this.makereadyValue && this.otherActivityValue;
        this.settingsConfirmationService.pushConfirmationSettings(this.confirmationData);
    }

    public changeMakereadyValue(): void {
        this.makereadyValue = !this.makereadyValue;
        this.confirmationData.makeready = this.makereadyValue;
        this.showAllDialogs = this.productionRunValue && this.makereadyValue && this.otherActivityValue;
        this.settingsConfirmationService.pushConfirmationSettings(this.confirmationData);
    }

    public changeOtherActivityValue(): void {
        this.otherActivityValue = !this.otherActivityValue;
        this.confirmationData.otherActivity = this.otherActivityValue;
        this.showAllDialogs = this.productionRunValue && this.makereadyValue && this.otherActivityValue;
        this.settingsConfirmationService.pushConfirmationSettings(this.confirmationData);
    }

    public changePrintPalletSheetValue(): void {
        this.printPalletSheetValue = !this.printPalletSheetValue;
        this.confirmationData.printPalletSheet = this.printPalletSheetValue;
        this.showAllDialogs =
            this.productionRunValue && this.makereadyValue && this.otherActivityValue && this.printPalletSheetValue;
        this.settingsConfirmationService.pushConfirmationSettings(this.confirmationData);
    }

    public changeAllDialogs(): void {
        this.showAllDialogs = !this.showAllDialogs;
        this.productionRunValue = this.showAllDialogs;
        this.makereadyValue = this.showAllDialogs;
        this.otherActivityValue = this.showAllDialogs;
        this.printPalletSheetValue = this.showAllDialogs;
        this.confirmationData.productionRun = this.productionRunValue;
        this.confirmationData.makeready = this.makereadyValue;
        this.confirmationData.otherActivity = this.otherActivityValue;
        this.confirmationData.printPalletSheet = this.printPalletSheetValue;
        this.settingsConfirmationService.pushConfirmationSettings(this.confirmationData);
    }

    public changeIdleValue(): void {
        this.idleValue = !this.idleValue;
        this.confirmationData.idle = this.idleValue;
        this.settingsConfirmationService.pushConfirmationSettings(this.confirmationData);
    }

    public changeIdleTime(): void {
        this.confirmationData.idletime = this.idleTime;
        this.settingsConfirmationService.pushConfirmationSettings(this.confirmationData);
    }

    public getDefaultValues(): ConfirmationSettings {
        return SettingsConfirmationService.getDefaultConfirmationData();
    }
}

@NgModule({
    declarations: [SettingsConfirmationComponent],
    exports: [SettingsConfirmationComponent],
    imports: [
        FormsModule,
        MatSlideToggleModule,
        CommonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        HdmuiInfoBoxModule,
    ],
})
export class SettingsConfirmationModule {}
