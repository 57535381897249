@if (!isDataTerminalUser) {
    <hdmui-info-box [icon]="'hdmui:exclamationMarkCircled'" [background]="'full'">
        <p>
            {{ 'DC.SETTINGS.USER_NOT_FOUND_ONPREM' | translate }}
        </p>
    </hdmui-info-box>
}
<section class="hdmui">
    <h1>{{ 'DC.SETTINGS.NOTIFICATIONS.TITLE' | translate }}</h1>
    <div class="hdmui-content hdmui-m-4 hdmui-background-2">
        <p>{{ 'DC.SETTINGS.NOTIFICATIONS.DESCRIPTION' | translate }}</p>
        <p>
            <span class="hdmui-mt-3"> {{ 'DC.SETTINGS.NOTIFICATIONS.SHOW_ALL' | translate }} </span>
            <span class="hdmui-spacer"></span>
            <mat-slide-toggle
                [checked]="showAllDialogs"
                (change)="changeAllDialogs()"
                [disabled]="!isDataTerminalUser"
            ></mat-slide-toggle>
        </p>
        <mat-divider></mat-divider>
        <p>
            <span class="hdmui-mt-3"> {{ 'DC.SETTINGS.NOTIFICATIONS.PRODUCTIONRUN' | translate }} </span>
            <span class="hdmui-spacer"></span>
            <mat-slide-toggle
                [checked]="productionRunValue"
                (change)="changeProductionRunValue()"
                [disabled]="!isDataTerminalUser"
            ></mat-slide-toggle>
        </p>
        <p>
            <span class="hdmui-mt-3"> {{ 'DC.SETTINGS.NOTIFICATIONS.OTHERACTIVITY' | translate }} </span>
            <span class="hdmui-spacer"></span>
            <mat-slide-toggle
                [checked]="otherActivityValue"
                (change)="changeOtherActivityValue()"
                [disabled]="!isDataTerminalUser"
            ></mat-slide-toggle>
        </p>
        <p>
            <span class="hdmui-mt-3"> {{ 'DC.SETTINGS.NOTIFICATIONS.MAKEREADY' | translate }} </span>
            <span class="hdmui-spacer"></span>
            <mat-slide-toggle
                [checked]="makereadyValue"
                (change)="changeMakereadyValue()"
                [disabled]="!isDataTerminalUser"
            ></mat-slide-toggle>
        </p>
        <p>
            <span class="hdmui-mt-3"> {{ 'DC.SETTINGS.NOTIFICATIONS.PRINTPALLETSHEET' | translate }} </span>
            <span class="hdmui-spacer"></span>
            <mat-slide-toggle
                [checked]="printPalletSheetValue"
                (change)="changePrintPalletSheetValue()"
                [disabled]="!isDataTerminalUser"
            ></mat-slide-toggle>
        </p>
    </div>

    <h1>{{ 'DC.SETTINGS.NOTIFICATIONS.TITLE2' | translate }}</h1>
    <div class="hdmui-content hdmui-m-4 hdmui-background-2">
        <p>{{ 'DC.SETTINGS.NOTIFICATIONS.DESCRIPTION2' | translate }}</p>
        <p>
            <span class="hdmui-mt-3">{{ 'DC.SETTINGS.NOTIFICATIONS.ENABLE_LOCK' | translate }} </span>
            <span class="hdmui-spacer"></span>
            <mat-slide-toggle
                [checked]="idleValue"
                (change)="changeIdleValue()"
                [disabled]="!isDataTerminalUser"
            ></mat-slide-toggle>
        </p>
        <p class="settings-notification__log-off">
            {{ 'DC.SETTINGS.NOTIFICATIONS.LOG_AFTER' | translate }}
            <mat-form-field>
                <input
                    [(ngModel)]="idleTime"
                    [disabled]="!idleValue || !isDataTerminalUser"
                    type="number"
                    name="idleTimeInput"
                    matInput
                    [value]="idleTime"
                    (change)="changeIdleTime()"
                />
                <span matSuffix>
                    {{ 'DC.SETTINGS.NOTIFICATIONS.LOG_MIN' | translate }}
                </span>
            </mat-form-field>
        </p>
    </div>
</section>
